.map-wrap {
    position: relative;
    width: 100%;
    /* height: calc(100vh - 77px); calculate height of the screen minus the heading */
  }
  
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  }

.start-marker::after {
    content: "Start";
    color: #fff;
    position: absolute;
    margin-top: -70px;
    background-color: cornflowerblue;
    padding: 4px;
    border-radius: 5px;
}

.end-marker::after {
    content: "End";
    color: #fff;
    position: absolute;
    margin-top: -70px;
    background-color: cornflowerblue;
    padding: 4px;
    border-radius: 5px;
}

.same-as-start-marker {
    margin: 0.5em;
}

.maplibregl-popup {
    max-width: 348px !important;
}

.maplibregl-popup span {
    font-weight: 900;
    color: cornflowerblue;
    margin-right: 8px;
}